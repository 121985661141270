/* You can add global styles to this file, and also import other style files */

html {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

$primaryColorDark: #3667F6;
$primaryColorLight: #0091ff;
// $primaryBgColor: #fe5f552e;
$secondaryColor: #191847;
$white: #ffffff;
$black: #000000;
$grey: #E6E6E6;
$gold: #b7ac7f;
$fontPoppins: 'Poppins';
$fontSans: 'sans-serif';
$raleway: 'Raleway', sans-serif;
$spectral: 'Spectral', serif;

@font-face {
	font-family: $fontPoppins;
	src:url('assets/fontsMinify/Poppins-regular.eot');
	src:local(''),
        url('assets/fontsMinify/Poppins-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('assets/fontsMinify/Poppins-regular.woff2') format('woff2'), /* Modern Browsers */
		url('assets/fontsMinify/Poppins-regular.woff') format('woff'), /* Super Modern Browsers */
		url('assets/fontsMinify/Poppins-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('assets/fontsMinify/Poppins-regular.svg#Poppins') format('svg'); /* Legacy iOS */
	font-weight: normal;
	font-style: normal;
    font-display: swap;
}
@font-face {
	font-family: $fontPoppins;
	src:url('assets/fontsMinify/Poppins-Black.eot');
	src:local(''),
        url('assets/fontsMinify/Poppins-Black.eot?#iefix') format('embedded-opentype'),
		url('assets/fontsMinify/Poppins-Black.woff2') format('woff2'),
		url('assets/fontsMinify/Poppins-Black.woff') format('woff'),
		url('assets/fontsMinify/Poppins-Black.ttf') format('truetype'),
		url('assets/fontsMinify/Poppins-Black.svg#Poppins') format('svg');
	font-weight: 700;
	font-style: normal;
    font-display: swap;
}
@font-face {
	font-family: $fontPoppins;
	src:url('assets/fontsMinify/Poppins-Bold.eot');
	src:local(''),
        url('assets/fontsMinify/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
		url('assets/fontsMinify/Poppins-Bold.woff2') format('woff2'),
		url('assets/fontsMinify/Poppins-Bold.woff') format('woff'),
		url('assets/fontsMinify/Poppins-Bold.ttf') format('truetype'),
		url('assets/fontsMinify/Poppins-Bold.svg#Poppins') format('svg');
	font-weight: 900;
	font-style: normal;
    font-display: swap;
}
body {
    font-family: $fontPoppins, $fontSans;
    position: relative;
    background: $white;
    overflow-x: hidden;
}
h1,h5{
	color: $secondaryColor;
}
h1{
	font-size: 56px;
	font-weight: 700;
}
h5{
	font-size: 21px;
}
.miniHeader{
	color: $primaryColorDark;
	font-weight: 700;
	text-transform: uppercase;
}
button:focus {
    // border: none;
    outline: none;
    box-shadow: none;
  }

  .btn{
    transition:none !important;
  }
  
.heroHeaderButton {
    height: 45px;
    width: 150px;
    border-radius: 5px;
    color: $white;
    // border: 2px solid var(--secondary-color);
    background: $primaryColorDark;
    box-shadow: 5px 5px 25px #00000017;
    // background: linear-gradient(10deg, $primaryColorDark 0%, $primaryColorLight 100%);
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
  }
  
  .heroHeaderButton:hover {
    // background: $primaryColorDark;
    background: linear-gradient(10deg, $primaryColorDark 0%, $primaryColorLight 100%);
    box-shadow: none;
    border: none;
    color: $white;
    transition: .1s all ease-out ;
  }
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	// color: #E6E6E6;
	// opacity: 1; 
  }

  .alert-danger{
    color: #ffffff;
    background-color: #dc3545;
    border-color: #f5c6cb00;
    font-size: 12px;
  }

// /* Set the effects to the division            
// 	/* Designing for scroll-bar */ 
// 	::-webkit-scrollbar { 
// 		width: 8px; 
// 	} 
	
// 	/* Track */ 
// 	::-webkit-scrollbar-track { 
// 		background: gainsboro; 
// 		border-radius: 15px; 
// 	} 
	
// 	/* Handle */ 
// 	::-webkit-scrollbar-thumb { 
// 		background:linear-gradient(10deg, hsl(5, 88%, 67%) 0%, #ffa9a1 100%);; 
// 		// box-shadow: 15px 15px 25px #ff0000a4;
// 		border-radius: 15px; 
// 	} 
	
// 	/* Handle on hover */ 
// 	::-webkit-scrollbar-thumb:hover { 
// 		background: $primaryColorLight; 
// 	} 

// Responsive Media Query for all
@media (max-width: 575.18px) { 
	h1{
		font-size: 32px;
	}
	h5{
		font-size: 18px;
	}

}
@media (min-width: 575.98px) { 

}
@media (max-width: 767.08px) { 

}
@media (min-width: 767.98px) {   

}
@media (min-width: 991.98px) { 

}

// ipad 
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    h1{
        font-size: 36px;
    }
}





// Responsive Media Query for all
@media (max-width: 575.18px) { 

}
@media (min-width: 575.98px) { 

}
@media (max-width: 767.08px) { 

}
@media (min-width: 767.98px) {   

}
@media (max-width: 991.98px) { 

}
@media (min-width: 991.98px) { 

}